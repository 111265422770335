import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import './registerServiceWorker'
// import './plugins/element.js'
import util from './utils.js'
import api from './api.js'
import headerBox from './components/header.vue'
import footerBox from './components/footer.vue'
Vue.component('headerBox',headerBox)
Vue.component('footerBox',footerBox)
Vue.config.productionTip = false
Vue.prototype.apiHOST = '/api'
Vue.prototype.api = api
Vue.prototype.util = util
Vue.prototype.$Toast = Vant.Toast
Vue.use(Vant);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
