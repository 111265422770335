<template>
  <div id="app">
    <router-view></router-view>
    <pwaUpdate></pwaUpdate>
  </div>
</template>

<script>
import pwaUpdate from "./views/pwaUpdate";
export default {
  components: {
    pwaUpdate,
  },
  name: "app",
  created() {
    window.addEventListener('appinstalled', function () {

    });
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.$store.state.pwa = e;
    });
  },
};
</script>

<style>
*{
	border: 0;
	padding: 0;
	margin: 0;
}
body,html{
	height: 100%;
}

</style>
